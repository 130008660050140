* {
    box-sizing: border-box;
  }

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}



[class*="col-"] {
    float: left;
    
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.main-container {
    background-image: url('https://i.ibb.co/Zh4Tfks/letters-a.jpg');
    padding-bottom: 50px;
}

.returned-data-container {
    margin: 0 auto;
    max-width: 700px;
}

.returned-data {
    padding:1%;
    margin: 0px auto;
}

#hearing-img {
    position: relative;
    top: 12px;
}

.returned-data h2 {
    text-align:center;
    font-family: 'Special Elite', cursive;
    font-size: 2em;
    letter-spacing: .22em;
}

#word-searched {
    height: 200px;   
}

#definitions {
    min-height: 300px;
}

.style-solid-dark {
    background-color: black;
    color: white;
    border: 5px solid white;
    margin: 5.5em;
}

.style-solid-transparent {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 2em;
    border: 10px solid white;
}

.style-solid-transparent h2 {
    text-shadow: 2px 2px rgb(252, 251, 251);
}

#pronunciation {
    height: 200px;
}

#audio-container, #word-container {
    display: flex;
    justify-content: center;
    background-color: black;
    border: 5px solid white;
    min-height: 85px;
}

audio {
    padding:1em;
    background-color: black;
    height: 75px;
}

#examples {
    height: 100px;
    background-color: blue;
}

#more-info {
    height: 100px;
    background-color: orange;
}

div.col-8 {
    margin-left: 15%;
}

h1 {
    color: white;
    font-family: 'Special Elite', cursive;
}


form{
    text-align: center;
    margin-top: 135px;
}

input {
    display: block;
    outline: 2px solid black;
    width: 75%;
    max-width: 300px;
    height: 75px;
    margin: 0 auto;
}

button {
    display: block;
    margin: 20px auto;
    padding: 10px 15px;
    background-color: black;
    color: white;
    font-family: 'Special Elite', cursive;
    font-size: 1.25rem;
    letter-spacing: .25rem;
}

button:hover {
    transform: scale(1.1);
}

input[placeholder], [-moz-placeholder], [-ms-input-placeholder] {
    font-family: 'Special Elite', cursive;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: .15rem;
}

#word-container ul {
    padding: .5em;
}

ul {
    font-family: 'Special Elite', cursive;
    padding: 2rem;
    margin: 0 auto;
    font-size: 2em;
    color: white; 
}

li {
    font-family: 'Special Elite', cursive;
    margin-bottom: 1.5rem;
    color: white;
    font-size: 2rem; 
}
/* media queris-------------------------------------------------------------- */
@media only screen and (max-width: 1024px) {
    /* For laptops: */
    
}

@media only screen and (max-width: 768px) {
    /* For tablets: */
    .style-solid-dark {
        background-color: black;
        color: white;
        border: 5px solid white;
        margin: 3em;
    }
    .style-solid-transparent {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 1em;
        border: 10px solid white;
    }
    
}

@media only screen and (max-width: 425px) {
    /* For l mobile phones: */
    form{
        text-align: center;
        margin-top: 35px;
    }

    [class*="col-"] {
        width: 100%;    
    }

    #word-searched {
        height: 200px;
        
        margin: 0%;   
    }

    .style-solid-dark {
        background-color: black;
        color: white;
        border: 5px solid white;
        margin: 1em;
    }

    .style-solid-transparent {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 1em;
        border: 10px solid white;
    }
}

@media only screen and (max-width: 375px) {
    /* For m mobile phones: */
    form{
        text-align: center;
        margin-top: 25px;
    }

    [class*="col-"] {
        width: 100%;    
    }

    #word-searched {
        height: 200px;
        
        margin: 0%;    
    }

    .style-solid-dark {
        background-color: black;
        color: white;
        border: 5px solid white;
        margin: 1em;
    }

    .style-solid-transparent {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 1em;
        border: 10px solid white;
    }
}

@media only screen and (max-width: 320px) {
    /* For s mobile phones: */
    form{
        text-align: center;
        margin-top: 15px;
    }

    [class*="col-"] {
        width: 100%;    
    }

    #word-searched {
        height: 200px;
        margin: 0%;   
    }
    
    .returned-data h2 {
        text-align:center;
        font-family: 'Special Elite', cursive;
        font-size: 1.75em;
        letter-spacing: .14em;
    }

    .style-solid-dark {
        background-color: black;
        color: white;
        border: 5px solid white;
        margin: 1em;
    }

    .style-solid-transparent {
        background-color: rgba(255, 255, 255, 0.5);
        margin: 1em;
        border: 10px solid white;
    }
  }



 