#styled-header-div-a {
    display: block;
    position: relative;
    height: 3em;
    background-color: gray;
    margin: 0px;
    padding: 0px;
    transform: skewY(-2deg);
    top: -81px;
}

#styled-header-div-b {
    display: block;
    position: relative;
    height: 4em;
    background-color: #808080;
    margin: 0px;
    padding: 0px;
    top: -23px;
    box-shadow: 2px 2px 112px black;
}

#g {
    position: relative;
    right: 17px;
}

#g, #t { 
    display: inline-block;
    font-size: 10em;
    margin: 0px;    
}

#rammar, #hun{
    display: inline-block;
    letter-spacing: 1em;
    font-size: 3em;
    width: auto;
}

#app-title {
    text-align: center;
    z-index: 10;
    padding-top: 3em;
}

h1#t {
    margin-left: -33px;
}

.container {
    background-repeat: no-repeat;
    background-size: auto;
    background-color: black;
    width: 100%;
    height: 200px;
    box-shadow: 2px 2px 2px black;
}

a li {
    color: white;
    font-family: 'Special Elite', cursive;
    font-size: 1em;
    list-style: none;
    display: inline-block;
    margin: 5px;
    padding: 5px;
}

a:link {
    text-decoration: none;
  }

a:hover {
    text-decoration: none;
    font-size: 1.5em;

  }

@media only screen and (max-width: 787px) {
    /* For tablets: */
    #rammar, #hun{
        display: inline-block;
        letter-spacing: .50em;
        font-size: 3em;
        width: auto;
    }
    
    #app-title {
        text-align: center;
        z-index: 10;
        padding-top: 3em;
    }

    #g {
        position: relative;
        right: 4px;
    }

    #g, #t { 
        display: inline-block;
        font-size: 10em;
        margin: 0px;
        
    }
    
    h1#t {
        margin-left: -30px;
    }
    
}

@media only screen and (max-width: 425px) {
    /* For l mobile phones: */
    #rammar, #hun{
        display: inline-block;
        letter-spacing: .25em;
        font-size: 2em;
        width: auto;
    }
    
    #app-title {
        text-align: center;
        z-index: 10;
        padding-top: 3em;
    }

    #g {
        position: relative;
        right: 4px;
    }

    #g, #t { 
        display: inline-block;
        font-size: 6em;
        margin: 0px;
        
    }
    
    h1#t {
        margin-left: -10px;
    }
}

@media only screen and (max-width: 375px) {
    /* For m mobile phones: */
    #rammar, #hun{
        display: inline-block;
        letter-spacing: .25em;
        font-size: 2em;
        width: auto;
    }
    
    #app-title {
        text-align: center;
        z-index: 10;
        padding-top: 3em;
    }

    #g {
        position: relative;
        right: 0px;
    }

    #g, #t { 
        display: inline-block;
        font-size: 6em;
        margin: 0px;
        
    }
    
    h1#t {
        margin-left: -12px;
    }
}

@media only screen and (max-width: 320px) {
    /* For s mobile phones: */
    #rammar, #hun{
        display: inline-block;
        letter-spacing: .25em;
        font-size: 2em;
        width: auto;
    }
    
    #app-title {
        text-align: center;
        z-index: 10;
        padding-top: 3em;
    }

    #g {
        position: relative;
        right: 0px;
    }

    #g, #t { 
        display: inline-block;
        font-size: 4em;
        margin: 0px;    
    }
    
    h1#t {
        margin-left: -13px;
    }

    .container {
        background-repeat: no-repeat;
        background-size: auto;
        background-color: black;
        width: 100%;
        height: 175px;
        box-shadow: 2px 2px 2px black;
    }
  }